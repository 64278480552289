<template>
  <div>
    <hr>
    <b-row class="mt-1">
      <b-col lg="8" md="6" sm="12" cols="12">
        <b-card-actions
            class="cari-liste-card"
        >
          <b-row class="mt-50 mb-3">
            <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
              <b-col cols="12">
                <h4 class="text-white d-inline-block">{{ odaAdi }} İsimli Odaya Ait Resimler</h4>
              </b-col>
            </div>
          </b-row>
          <hr>
          <table
              class="table table-striped"
              variant="cardTitle-L"
          >
            <thead>
            <tr>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                Foto Önizleme
              </th>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                Foto Açıklaması
              </th>
              <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in odaResim"
                :key="index"
            >
              <td>
                <b-img
                    :src="imagePath(item.foto)"
                    fluid
                    alt="Responsive image"
                    class="my-image"
                />
              </td>
              <td>
                {{ item.aciklama }}
              </td>
              <td>
                <b-button
                    variant="bgDanger"
                    class="btn-icon rounded-circle"
                    @click="odaResimRemove(item.fotoID)"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </td>
            </tr>
            </tbody>
          </table>
        </b-card-actions>
      </b-col>
      <b-col lg="4" md="6" sm="12" cols="12">
        <b-card-actions
            class="cari-liste-card"
        >
          <b-row class="mt-50 mb-3">
            <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
              <b-col cols="12">
                <h4 class="text-white d-inline-block">Resim Yükle</h4>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="bgInfo"
                    class="myButton mr-1 text-white border-0 float-right"
                    to="/odalar"
                    :style="{backgroundColor:$store.getters.cardTitleButton}"
                >
                  <feather-icon
                      icon="ChevronLeftIcon"
                      class="mr-50"
                  />
                  Oda kayıtları
                </b-button>
              </b-col>
            </div>
          </b-row>
          <hr>
          <b-form-group
              label="Dosya Açıklaması"
              label-for="basicInput"
              class="mt-1"
          >
            <b-form-input
                id="basicInput"
                v-model="content"
                autocomplete="off"
            />
          </b-form-group>
          <b-form-group
              label="Lütfen Dosya Seçiniz"
              label-for="basicInput"
          >
            <b-form-file
                type="file"
                placeholder="Dosya Seçiniz"
                drop-placeholder="Drop file here..."
                accept=".jpg, .png, .gif, .jpeg"
                :file-name-formatter="formatNames"
                multiple
                @change="onFileSelected"
            />
          </b-form-group>
          <b-row>
            <b-col cols="9">
              <b-progress
                  v-model="value"
                  max="100"
              />
            </b-col>
            <b-col
                cols="3"
                class="text-right pr-0"
            >
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  variant="bgInfo border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="onUpload"
              >
                Yükle
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr

flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  components: {
    BCardActions,
    flatPickr,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      odaID: this.$route.params.id,
      odaAdi: '',
      content: '',
      value: 0,
      selectedFile: [],
    }
  },
  computed: {
    odaResim() {
      return this.$store.getters.odaResimler.liste
    },
  },
  created() {
    if (this.odaID) {
      this.$store
          .dispatch('odaResimler', { id: this.odaID })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
            this.odaAdi = res.oda.odaAdi
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    }
  },
  beforeDestroy() {
    this.$store.commit('clearOdaResimler')
  },
  methods: {
    imagePath(path) {
      return (
          `${this.$store.state.API_URL}userFiles/${
              localStorage.getItem('otelFirmaKodu')
          }/Galeri/kucuk/${
              path}`
      )
    },
    onUpload(e) {
      const fd = new FormData()
      this.selectedFile.forEach((element, index) => {
        fd.append('resim[]', element)
      })
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('serviceName', 'web')
      fd.append('methodName', 'odaResimleri')
      fd.append('odaID', this.odaID)
      fd.append('aciklama', this.content)
      fd.append('fotoKaydi', true)
      axios
          .post(store.state.POST_URL, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
              this.value = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 95),
              )
            }.bind(this),
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$store.dispatch('odaResimler', { id: this.odaID })
            this.selectedFile = []
            this.content = ''
            this.value = 0
            const mesaj = this.$store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  },
                  { position },
              )
            }
          })
          .catch(err => {
            this.value = 0
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'danger',
              },
            })
          })
    },
    odaResimRemove(val) {
      this.$store
          .dispatch('odaResimRemove', {
            id: val,
            odaID: this.odaID,
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    onFileSelected(event) {
      event.target.files.forEach((image, index) => {
        this.selectedFile.push(image)
      })
    },
    formatNames(files) {
      return files.length === 1
          ? files[0].name
          : `${files.length} resim seçildi`
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: "Dosya" !important;
}
</style>
<style scoped>
tr {
  text-align: center !important;
}

[dir] .table-striped tbody tr:nth-of-type(odd) {
  text-align: center;
}

.my-image {
  width: 140px !important;
}

.fw-bold {
  font-weight: 900 !important;
}

.progress {
  height: 0.557rem !important;
}

.my-down-button {
  margin: 0 auto;
  display: flex;
}

.opacity {
  opacity: 0.4 !important;
}

.row[data-v-61f55772] {
  justify-content: start;
}

.quill-editor {
  height: 305px;
}

.line {
  height: 2px;
  background-color: #ebe9f1;
  width: 100%;
}

.my-top-row {
  justify-content: space-between;
  display: flex;
}

@keyframes mymove {
  0% {
    background-color: pink;
  }

  25% {
    background-color: pink;
  }

  50% {
    background-color: #00ffd1;
  }

  75% {
    background-color: #00ffd1;
  }

  100% {
    background-color: #00ffd1;
    width: 100%;
  }
}

.editOnPrgoress {
  width: 0px;
  height: 4px;
  background: #38e54d;
  position: relative;
  animation: mymove 4000ms infinite;
  border-radius: 20px;
  transform: translateY(20px);
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td {
  text-align: center;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  padding: 3px;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

[dir] .table th,
[dir] .table td {
  padding: 3px !important;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

th {
  color: white !important;
  padding: 3px !important;
  border: none;
  text-align: center;
}
</style>
